<template>
  <div id="properties-audits">
    <b-row>
      <b-col cols="12">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <b-row>
              <b-col xl="3" sm="12">
                <h5>Identificador</h5>
                <b-form-input v-model="audits.identifier" disabled />
              </b-col>
              <b-col xl="3" sm="12">
                <h5>Modelo</h5>
                <b-form-input v-model="audits.model" disabled />
              </b-col>
              <b-col xl="3" sm="12">
                <h5>Orientación</h5>
                <b-form-input v-model="audits.orientation" disabled />
              </b-col>
              <b-col xl="3" sm="12">
                <h5>Torre</h5>
                <b-form-input v-model="audits.tower" disabled />
              </b-col>
               
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="mt-2">
        <b-card no-body class="mb-0">
          <properties-audits-list :audits="audits"></properties-audits-list>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import PropertiesAuditsList from '../properties/components/PropertiesAuditsList'

  export default{
    name: 'PropertiesAuditsView',
    components: {PropertiesAuditsList},
    props: ['audits'],
    data() {
      return {
      }
    },
    mounted() {
       
    },
    computed: {
      
    }
  }
</script>